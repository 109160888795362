import "./styles/index.scss";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import store from "./helpers/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            textHoverBg: "#FFFFFF",
          },
        }}
      >
        <BrowserRouter >
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
);
